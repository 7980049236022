<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-25 11:22:19
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-07 08:49:09
-->
<template>
  <body-layer>
    <div class="base" v-if="show">
      <div class="top">
        <div class="banner" :style="`background-image: url(${body.thumbnail})`"></div>
        <div class="right">
          <div class="title">{{body.post_title}}</div>
          <!-- <div class="tag">
            <i>考研</i>
            <i>考研</i>
            <i>考研</i>
          </div> -->
          <div class="tag" v-if="body.post_keywords">
            <i v-for="(d, idx) in body.post_keywords.split(',')" :key="idx">{{d}}</i>
          </div>
          <div class="price">{{body.price}}<span>元/科起</span></div>
          <div class="btns" @click="$router.push({name: 'advisory'})">立即咨询</div>
        </div>
      </div>
      <div class="tab">
        <div class="item" :class="{active: item.id == active}" v-for="(item, index) in tab" :key="index" @click="active=item.id"><span>{{item.name}}</span></div>
      </div>
      <div class="context" v-if="active == 1">
        <div v-html="body.post_content"></div>
      </div>
      <div class="live" v-else>
        <template v-for="item in list">
          <div class="big">{{item.name}}</div>
          <div class="small">
            <div class="item" v-for="(d, idx) in item.videos" :key="idx" @click="goVideo(d)">
              <div class="live-title">
                <img src="@/assets/images/VIP.png" alt="" v-if="d.is_vip">
                <span>{{d.name}}</span>
              </div>
              <div class="find">
                <div>时长：{{d.video_time == null ? '' : ((d.video_time / 3600 - (d.video_time / 3600 % 1)).toFixed(0) + 'h' + ((d.video_time % 3600) / 60).toFixed(0) + 'min')}}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <el-dialog :visible.sync="dialogTableVisible" append-to-body width="400px" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" custom-class="diaAtrel">
      <div class="contents">VIP用户才可以查看哦</div>
      <div class="footers">
        <div class="item q" @click="(dialogTableVisible=false)">取消</div>
        <div class="item c" @click="$router.push({name: 'advisory'})">去咨询</div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogTableVisible2" append-to-body width="400px" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" custom-class="diaAtrel">
      <div class="contents">请开通VIP解锁观看</div>
      <div class="footers">
        <div class="item q" @click="(dialogTableVisible2=false)">取消</div>
        <div class="item c" @click="$router.push({name: 'advisory'})">去咨询</div>
      </div>
    </el-dialog>
  </body-layer>
</template>

<script>
import bodyLayer from '@/components/bodyLayer.vue';
import http from '@/util/http'
export default {
  components: { bodyLayer },
  data(){
    return{
      id: '',
      dialogTableVisible: false,
      show: false,
      active: 1,
      tab: [{name: '课程简介', id: 1}, {name: '课程目录', id: 2}],
      body: '',
      list: [],
      dialogTableVisible2: false
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    async getData(){
      if(!this.$store.getters.uniqid){
        this.show = false;
        return;
      }
      var params = {
        id: this.id,
        uniqid: this.$store.getters.uniqid
      }
      var { data } = await http.post('/api/course/getCourse', params);
      this.body = data;
      this.show = true;
      this.contents();
    },
    async contents(){
      var params = {
        id: this.id
      }
      var { data } = await http.post('/api/course/getContents', params);
      this.list = data;
    },
    goVideo(item){
      if(this.body.is_purchase == 0) return this.dialogTableVisible2 = true;
      var userInfo = this.$store.state.userInfo;
      if(item.is_vip == 1){
        if (!this.$store.getters.uniqid){
          this.$root.Bus.$emit('showLogin')
          return
        }else{
          if(userInfo.is_vip == 0){
            this.dialogTableVisible = true;
            return;
          }else{
            this.$router.push({
              name: 'video',
              query: {
                kid: this.id,
                id: item.id
              }
            })
          }
        }
      }else{
        this.$router.push({
          name: 'video',
          query: {
            kid: this.id,
            id: item.id
          }
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.base {
  width: 100%;
  background-color: #fff;
  padding: 30px;
  position: relative;
  .live{
    padding-top: 30px;
    .small{
      padding-left: 20px;
      .item{
        padding-bottom: 30px;
        border-bottom: 1px solid #E8E8E8;
        padding-top: 30px;
        cursor: pointer;
        &:last-child{
          border-bottom: 0;
        }
        .find{
          color: #999999;
          font-size: 14px;
          display: flex;
          padding-top: 13px;
          div:first-child{
            padding-right: 30px;
          }
        }
        .live-title{
          font-size: 16px;
          color: #333333;
          display: flex;
          align-items: center;
          img{
            width: 36px;
            height: 20px;
            margin-right: 5px;
          }
        }
      }
    }
    .big{
      height: 48px;
      line-height: 48px;
      background: rgba(255,237,229,1);
      font-size: 18px;
      color: #333333;
      margin-top: 30px;
      padding-left: 20px;
    }
  }
  .context{
    padding-top: 40px;
    font-size: 18px;
    ::v-deep{
      img{
        width: 100%;
      }
    }
    
  }
  .tab{
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
    display: flex;
    .item{
      padding-bottom: 20px;
      margin-right: 60px;
      cursor: pointer;
      &.active{
        span{
          color: #333333;
          &::after{
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            display: inline-block;
            width: 40px;
            height: 4px;
            background-color: #FF6A29;
          }
        }
      }
      span{
        font-size: 20px;
        color: #666666;
        position: relative;
        padding-bottom: 20px;
        
      }
    }
  }
  .top{
    display: flex;
    padding-bottom: 60px;
    .right{
      padding-left: 20px;
      position: relative;
      .btns{
        position: absolute;
        left: 20px;
        bottom: 0;
        width: 140px;
        height: 40px;
        line-height: 40px;
        border-radius: 40px;
        background: rgba(255,106,41,1);
        text-align: center;
        color: #fff;
      }
      .price{
        font-size: 48px;
        color: #FF6A29;
        span{
          font-size: 26px;
        }
      }
      .tag{
        padding-bottom: 30px;
        i{
          padding: 0 10px;
          height: 32px;
          line-height: 32px;
          background: rgba(242,242,242,1);
          margin-right: 10px;
          text-align: center;
          font-size: 14px;
          color: #666666;
          font-style: normal;
        }
      }
      .title{
        font-size: 24px;
        color: #000;
        padding-bottom: 20px;
      }
    }
    .banner{
      width: 600px;
      height: 400px;
      font-size: 0;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
</style>